import { defineStore } from 'pinia'

interface NotificationState {
  notification: Object | null
}

export const useNotificationStore = defineStore('notification', {
    state: (): NotificationState => ({
      notification: null
    }),

    actions: {
      async retrieveNotification() {
        const response = await fetchActiveNotification()

        // Get response data and update notification state
        this.setNotification(Object.keys(response.data).length > 0 ? response.data : null)
      },
      setNotification(notification: Object) {
        this.notification = notification
      }
    },
})